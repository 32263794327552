import classNames from "classnames"

import { useTranslation } from "@bounce/i18n"

import type { StoreContact as StoreContactObj } from "../../../hooks/useStoreContact"

export enum StoreContactVariant {
  Primary = "primary",
  Secondary = "secondary",
}

export type StoreContactProps = {
  storeContact?: StoreContactObj
  className?: string
  variant?: StoreContactVariant
}

export const StoreContact = ({ storeContact, className, variant = StoreContactVariant.Primary }: StoreContactProps) => {
  const { t } = useTranslation()
  if (!storeContact) return null
  const { name, fullAddress, telephone } = storeContact
  const textColor = variant === StoreContactVariant.Primary ? "text-white" : "text-black"

  return (
    <div className={classNames("mx-auto flex w-full max-w-screen-xl flex-col px-6 text-center", className)}>
      <h2 className={classNames("footer__title", textColor)}>{name}</h2>
      <div className="footer__contact mt-1">
        <strong className={classNames("font-normal", textColor)}>{t("cmp.footer.address", "Address")}:</strong>{" "}
        <address className={classNames("inline not-italic", textColor)}>{fullAddress}</address>
      </div>
      <p className="footer__contact mt-1">
        <strong className={classNames("font-normal", textColor)}>{t("cmp.footer.phone", "Phone")}:</strong>{" "}
        <a href={`tel:${telephone}`} className={textColor}>
          {telephone}
        </a>
      </p>
    </div>
  )
}
